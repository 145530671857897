<template>
  <div>
    <div class="text-[#333333]  border-[#E2E2E2] border-[1px] rounded-[8px]">
      <div class="px-[24px] py-[12px] bg-[#FCD4BE] rounded-t-[8px]">
        <div>
          <div class="text-[16px] font-semibold">
            Auto Topup Komcards
          </div>
        </div>
      </div>
      <div class="px-[24px] py-[12px] text-[#333333]">
        Atur saldo kartu Komcards kamu tanpa repot dengan menggunakan fitur Auto
        Topup Komcards. Pilih kartu kemudian tentukan batas minimum saldo dan
        atur target saldo untuk setiap kartu yang kamu miliki
      </div>
      <div class="flex justify-end px-[12px]">
        <div class="flex gap-[10px] items-center">
          <div class="w-[200px] ">
            <div class="flex gap gap-[2px] w-full items-center relative">
              <img
                class="absolute h-[20px] ml-[7px]"
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/search-normal-1.svg"
                alt="search"
              >
              <BFormInput
                v-model="search_card"
                debounce="600"
                placeholder="Cari nama kartu"
                class="pl-3 pr-2"
              />
            </div>
          </div>
          <BDropdown
            id="dropdown-1"
            no-caret
            size="sm"
            toggle-class="toggle-custom"
            variant="primary"
            right
          >
            <template #button-content>
              <img
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/filter-search.svg"
                alt="filter"
              >
            </template>
            <div
              class="text-[#333333] my-1 font-semibold text-[14px] px-[10px] min-w-[250px]"
            >
              Filter
            </div>
            <hr>
            <BDropdown
              id="dropdown-2"
              variant="none"
              toggle-class="toggle-form"
              class="w-full text-left mr-[100px]"
              no-caret
              dropleft
              left
            >
              <template #button-content>
                <div class="text-left -ml-[10px] font-[400] text-[#333333]">
                  Status Auto Topup
                </div>
              </template>
              <div class="px-[10px] pt-[10px]">
                <b-form-group
                  class="text-red-100"
                >
                  <b-form-checkbox-group
                    id="checkbox-2"
                    v-model="status"
                    class="checkbox-styling"
                    stacked
                    :options="options_status"
                    name="checkbox-2"
                  />
                </b-form-group>
              </div>
            </BDropdown>
            <hr>
            <div class="px-[10px] my-1 grid grid-cols-2 gap-[4px]">
              <BButton
                variant="outline-primary"
                @click="handleReset"
              >
                Reset
              </BButton>
              <BButton
                variant="primary"
                @click="handleSubmit"
              >
                Terapkan
              </BButton>
            </div>
          </BDropdown>
        </div>
      </div>
      <div class="mt-[12px]">
        <BOverlay
          :show="loading.table"
          spinner-variant="primary"
          variant="light"
          class="mt-[16px]"
          blur="0"
          opacity=".5"
          rounded="sm"
        >
          <BTable
            :items="items"
            :fields="fields"
            show-empty
            empty-text="Tidak ada Kartu"
            responsive
          >
            <template #cell(card_name)="data">
              <div class="flex items-center">
                <span class="k-card text-[#626262] text-[20px]" />
                <div class="text-nowrap ml-[8px] font-semibold text-[#333333]">
                  {{ data.item.card_name }}
                </div>
                <div class="text-[#626262] text-nowrap  ml-[8px]">
                  **{{ data.item.last_number }}
                </div>
              </div>
            </template>
            <template #cell(is_active)="data">
              <div>
                <div
                  v-if="data.item.is_active === false"
                  class="w-full bg-[#FFEDED] text-[#E31A1A] rounded-[30px] py-[5px] text-center"
                >
                  Tidak Aktif
                </div>
                <div
                  v-if="data.item.is_active === true"
                  class="w-full bg-[#DCF3EB]  text-[#34A770] rounded-[30px] py-[5px] text-center"
                >
                  Aktif
                </div>
              </div>
            </template>
            <template #cell(minimal_balance)="data">
              <div
                class="text-center"
                :class="data.item.minimal_balance > 0 && 'text-black'"
              >
                {{
                  data.item.minimal_balance
                    ? IDR(data.item.minimal_balance)
                    : 'Rp-'
                }}
              </div>
            </template>
            <template #cell(nominal_target_balance)="data">
              <div
                class="text-center"
                :class="
                  data.item.nominal_target_balance > 0
                    ? 'text-black'
                    : 'text-[#828282]'
                "
              >
                {{
                  data.item.nominal_target_balance
                    ? IDR(data.item.nominal_target_balance)
                    : 'Rp-'
                }}
              </div>
            </template>
            <template #cell(option)="data">
              <div
                class="text-center cursor-pointer flex justify-center"
                @click="handleClickAction(data.item)"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/elements/setting-4.svg"
                  alt="option"
                >
              </div>
            </template>
          </BTable>
          <hr>
          <div
            class="flex justify-between items-center px-[24px] py-[20px]"
          >
            <div class="flex gap-[12px] items-center">
              <div>List per halaman</div>
              <div class="flex gap-[6px]">
                <div
                  :class="
                    pagination.per_page === 20 &&
                      'rounded-[8px] bg-[#F95031] max-h-[35px] text-white'
                  "
                  class="px-[11px] py-[5px]  max-h-[35px] text-black cursor-pointer"
                  @click="handlePerPage(20)"
                >
                  20
                </div>
                <div
                  :class="
                    pagination.per_page === 50 &&
                      'rounded-[8px] bg-[#F95031] max-h-[35px] text-white'
                  "
                  class="px-[11px] py-[5px] text-black cursor-pointer"
                  @click="handlePerPage(50)"
                >
                  50
                </div>
              </div>
            </div>
            <b-pagination
              v-model="pagination.page"
              size="md"
              :total-rows="pagination.total_row"
              :per-page="pagination.per_page"
              first-number
              last-number
              @change="handlePageChange"
            />
          </div>
        </BOverlay>
      </div>
      <ModalAutoTopup
        :id="prop.id"
        :cardlabel="prop.card_name"
        :minimal-amount="prop.minimal_balance"
        :target-amount="prop.nominal_target_balance"
        :status="prop.is_active"
        :last-number="prop.last_number"
        @refresh-table="getListTableTopup"
      />
    </div>
  </div>
</template>

<script>
import { IDR } from '@/libs/currency'
import axios from 'axios'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { newAxiosIns } from '@/libs/axios'
import columnConfig from './config'
import ModalAutoTopup from './ModalAutoTopup.vue'

export default {
  components: {
    ModalAutoTopup,
  },
  data() {
    return {
      IDR,
      search_card: null,
      prop: {
        id: null,
        card_name: null,
        minimal_balance: null,
        nominal_target_balance: null,
        is_active: null,
        last_number: null,
      },
      pagination: {
        page: 1,
        per_page: 20,
        total_row: null,
      },
      items: null,
      fields: columnConfig,
      loading: {
        table: false,
      },
      state_status: [],
      status: [],
      options_status: [
        {
          text: 'Aktif',
          value: 'active',
        },
        {
          text: 'Tidak Aktif',
          value: 'inactive',
        },
      ],
    }
  },
  watch: {
    search_card() {
      this.getListTableTopup()
    },
  },
  mounted() {
    this.getListTableTopup()
    this.$root.$on('bv::dropdown::show', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2') {
        this.isDropdown2Visible = true
        this.status = this.state_status
      }
    })
    this.$root.$on('bv::dropdown::hide', bvEvent => {
      if (bvEvent.componentId === 'dropdown-2') {
        this.isDropdown2Visible = false
      }
      if (this.isDropdown2Visible) {
        bvEvent.preventDefault()
      }
    })
  },
  methods: {
    handlePageChange(page) {
      this.pagination.page = page
      this.getListTableTopup()
    },
    handleReset() {
      this.status = []
      this.state_status = []
      this.search_card = null
      this.getListTableTopup()
    },
    handlePerPage(per_page) {
      this.pagination.per_page = per_page
      this.getListTableTopup()
    },
    handleSubmit() {
      this.state_status = this.status
      this.getListTableTopup()
    },
    handleClickAction(data) {
      this.prop.id = data.id
      this.prop.card_name = data.card_name
      this.prop.minimal_balance = data.minimal_balance
      this.prop.nominal_target_balance = data.nominal_target_balance
      this.prop.is_active = data.is_active
      this.prop.last_number = data.last_number

      setTimeout(() => {
        this.$bvModal.show('modal-auto-topup')
      }, 1)
    },
    handleScroll() {},
    async getListTableTopup() {
      this.loading.table = true
      const url = '/komcards/api/v1/setting/auto-topup'
      const params = {
        search: this.search_card,
        status: this.state_status.join(','),
        per_page: this.pagination.per_page,
        page: this.pagination.page,
      }

      try {
        const res = await newAxiosIns.request({
          method: 'get',
          url,
          params,
        })

        this.pagination.total = res.data.meta.pagination.total_page
        this.pagination.total_row = res.data.meta.pagination.total_row

        const { data } = res.data
        this.items = data
        this.loading.table = false
        return
      } catch (error) {
        const message = 'Gagal memuat data..'
        this.$toast(
          {
            component: ToastificationContentVue,
            props: {
              title: 'Gagal',
              icon: 'AlertCircleIcon',
              text: message,
              variant: 'danger',
            },
          },
          { timeout: 1000 },
        )
        this.loading.table = false
      }
    },
  },
}
</script>

<style >
.checkbox-styling label {
  color: #626262;
}

</style>
